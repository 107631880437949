/** @format */

/* Custom Below */
$gradient: linear-gradient(210deg, #943cff 0%, #dd45d3 40.13%, #fc9a57 90%);

// @import "/node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.css");

@font-face {
	font-family: "HKGrotesk";
	src: url("./fonts/HKGrotesk-Bold.woff2") format("woff2");
}

/* @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap'); */

body {
	font-family: "HKGrotesk", "Questrial", sans-serif;
	overflow-x: hidden;
	height: 100%;
	width: 100%;
	font-size: 14px;
}

h1 {
	font-family: "HKGrotesk", "Questrial", sans-serif;
	font-weight: 500;
}
h2 {
	margin: 0;
}
p {
	font-family: "Roboto", sans-serif;
}
// .App {
a {
	color: #943cff;
}
a:hover {
	color: #fc9a57;
	text-decoration: none;
}
// }
.wrapper {
	min-height: 100vh;
	margin-bottom: -10vh;
}
.header {
	//background-color: #282c34;
	// height: 20vh;
	display: flex;
	flex-direction: column;
	/* align-items: center;
	justify-content: center; */
	// font-size: calc(10px + 1vmin);
	color: white;
	font-size: min($numbers: 18px);
}
.nav {
	min-height: 6vh;
	background-color: white;
	padding: 1vh;
	justify-content: space-between;
}
.branding {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	min-width: 280px;
}

.logo {
	margin-right: 10px;
}
// .site-title {

// }
.logo img {
	height: 4vh;
	min-height: 24px;
}
.site-title {
	display: flex;
	align-items: center;
}
.site-title h1 {
	padding: 0;
	margin: 0;
	color: #555555;
	font-size: 3vh;
}
.site-title h1:hover {
	background: $gradient;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-decoration: none;
}
.form-header {
	text-align: center;
	margin-bottom: 2rem;
}
.links {
	color: #555555;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reversed;
	justify-content: flex-start;
	align-items: auto;
	align-content: center;
	font-size: 2vh;
	font-size: min($numbers: 18px);
}
// .main {

// }
.push {
	height: 10vh;
}
.footer {
	min-height: 10vh;
	background-color: #f8f9fa;
	align-items: center;
	padding: 1vh;
}
.footer ul {
	margin: 0;
}
// .footer h5 {
// }
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.bg-primary-faded {
	color: white;
	background: $gradient;
}

.btn.btn-primary {
	background: $gradient;
	border: 0;
}

.form-group {
	margin: 0;
}

.text-secondary {
	white-space: pre-wrap;
}
div.py-5.container > div.row > div.col > div {
	padding: 1rem;
	min-height: 12rem;
	min-width: 12rem;
}
div.py-5.container > div.row > div.col {
	padding-bottom: 30px;
	padding-left: 15px;
	padding-right: 15px;
}
main div.py-5.how-it-works {
	padding: 2rem 0 1rem 0 !important;
}
main div.py-5.bcform {
	padding-top: 0 !important;
}
footer h5 {
	margin-bottom: 0;
	min-width: 180px;
}
footer ul {
	padding: 0;
	word-break: break-all;
}
footer ul > li {
	font-size: 0.8rem;
}
footer a {
	color: #222222;
}
footer a:hover,
-webkit-tap-highlight-color {
	color: #943cff;
}
.h1-slider {
	font-size: 10vw !important;
}
@media screen and (min-width: 1024px) {
	.h1-slider {
		font-size: 120px !important;
	}
}
.p-slider {
	font-size: 4vw !important;
}
@media screen and (min-width: 1024px) {
	.p-slider {
		font-size: 48px !important;
	}
}
.form_error {
	color: #943cff;
	margin: -13px 5px 10px 13px;
	font-size: 0.8em;
	display: block;
	white-space: pre-wrap;
	word-break: keep-all;
	font-family: Helvetica, Arial, sans-serif;
}

.hidden {
	display: none !important;
}
.form-nav {
	margin: 0 20px 0 0;
}
.dob-gender-married {
	display: flex;
	align-items: flex-end;
}
select {
	width: 100%;
	min-height: 39px;
}
label {
	margin: 0 0 0.25rem 0;
}
div.interest label {
	min-width: 220px;
}
input,
select,
.radio-group {
	margin-bottom: 15px;
}
.radio-group {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	padding: 4px 10px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	min-height: 39px;
	background-clip: padding-box;
}
.radio-group:hover,
input:hover,
select:hover {
	color: unset;
	border-color: #943cff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(252, 154, 87, 0.5);
}
input:focus,
.form-control:focus {
	border-color: #943cff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(148, 60, 255, 0.35);
}
.form-check-input {
	margin-bottom: 0;
}
input.form-control::-webkit-input-placeholder,
input:focus::-webkit-input-placeholder,
.vNum {
	display: flex;
	align-items: center;
	line-height: 39px;
	min-height: 39px;
	overflow: hidden;
	white-space: nowrap;
}
@media (max-width: 768px) {
	.col-headers {
		display: none;
		visibility: hidden;
	}
}
.result {
	text-align: center;
	margin: 50px auto;
}
.contact-div {
	min-height: 700px;
	text-align: center;
}

.contact-div h1 {
	margin: 50px 0 25px 0;
}
.submit-message {
	margin: 10px auto;
}
.col {
	min-width:unset
}